<template>
  <div>
    <vx-card>
      <div class="flex justify-between items-center mb-4">
        <vs-input
          class="mb-4 w-full md:mb-0 mr-4"
          v-model.lazy="searchQuery"
          placeholder="Buscar..."
        />
        <div>
          <vs-button
            :to="{ name: 'terceros-crear-barrio' }"
            class="mb-4 md:mb-0"
            >Añadir</vs-button
          >
        </div>
      </div>

      <vs-table
        ref="table"
        :max-items="first"
        :total="paginatorInfo.total"
        :data="neighborhoods"
        v-if="neighborhoods"
        no-data-text="No hay resultados"
        @change-page="() => {}"
      >
        <template slot="thead">
          <vs-th sort-key="id">Ref</vs-th>
          <vs-th class="alarm-name-column" sort-key="">Nombre</vs-th>
          <vs-th sort-key="">Coordenadas.</vs-th>
          <vs-th sort-key="">Ciudad</vs-th>
          <vs-th>Acciones</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p>{{ tr.id }}</p>
              </vs-td>
              <vs-td>
                <p class="capitalize">{{ tr.name }}</p>
              </vs-td>
              <vs-td>
                <p>{{ tr.coordinates.join(',') }}</p>
              </vs-td>
              <vs-td>
                <p>
                  {{ tr.city.name }}
                </p>
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  icon="EditIcon"
                  @click="
                    $router.push({
                      name: 'terceros-editar-barrio',
                      params: { id: tr.id },
                    })
                  "
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
                <feather-icon
                  icon="TrashIcon"
                  @click="deleteNeighbor(tr.id)"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  class="ml-2"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>

      <div class="flex">
        <span v-show="loading"><p>Cargando...</p></span>

        <vs-pagination
          class="mt-3"
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </div>
    </vx-card>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import { NEIGHBORHOODS } from '@/graphql/queries';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable';
import { DELETE_NEIGHBORHOOD } from '@/graphql/mutations';
export default defineComponent({
  setup(_, { root }) {
    const searchQuery = ref('');
    const currentPage = ref(1);
    const first = ref(20);
    const maxPageNumbers = ref(5);

    const {
      result: neighborhoodsResult,
      loading,
      refetch,
    } = useQuery(NEIGHBORHOODS, {
      page: currentPage,
      first: first,
      search: searchQuery,
    });
    const neighborhoods = useResult(
      neighborhoodsResult,
      [],
      (data) => data.neighborhoods.data
    );
    const { mutate: deleteNeighborhood } = useMutation(DELETE_NEIGHBORHOOD);

    const paginatorInfo = useResult(neighborhoodsResult, [], (data) => ({
      total: data.neighborhoods.paginatorInfo.total,
      perPage: data.neighborhoods.paginatorInfo.perPage,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const deleteNeighbor = (id) => {
      root.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar eliminación`,
        text: `¿Estás seguro de borrar este barrio?`,
        accept: async () => {
          await deleteNeighborhood({
            id,
          });
          await refetch();
          root.$vs.notify({
            color: 'success',
            title: 'Barrio eliminado',
            text: 'El barrio fue correctamente eliminado',
          });
        },
        acceptText: 'Eliminar',
        cancelText: 'Cancelar',
      });
    };
    return {
      neighborhoods,
      totalPages,
      maxPageNumbers,
      loading,
      paginatorInfo,
      currentPage,
      first,
      searchQuery,
      deleteNeighbor,
    };
  },
});
</script>

<style lang="scss"></style>
